import { ImageLoader } from 'next/image';

export const datoImageLoader: ImageLoader = ({ src, width, quality }) => {
  const url = new URL(src);
  const ar64 = url.searchParams.get('ar64');
  url.searchParams.delete('ar64');
  url.searchParams.delete('q');
  url.searchParams.delete('fm');
  url.searchParams.delete('auto');
  let ar = 0;
  if (ar64 && typeof window !== 'undefined') {
    ar = window
      .atob(ar64)
      .split(':')
      .map((part) => parseInt(part, 10))
      .reduce<number>(
        (_previousValue, _currentValue, _currentIndex, all) =>
          (all[0] || 1) / (all[1] || 1),
        1,
      );
  }
  if (ar64 && typeof window === 'undefined' && typeof Buffer !== 'undefined') {
    ar = Buffer.from(ar64, 'base64')
      .toString('ascii')
      .split(':')
      .map((part) => parseInt(part, 10))
      .reduce<number>(
        (_previousValue, _currentValue, _currentIndex, all) =>
          (all[0] || 1) / (all[1] || 1),
        1,
      );
  }
  let w = parseFloat(url.searchParams.get('w') ?? '4');
  let h = parseFloat(url.searchParams.get('h') ?? '3');

  if (!ar) {
    ar = w / h;
  }
  w = Math.floor(width);
  h = Math.round(width / ar);
  url.searchParams.set('w', String(w));
  url.searchParams.set('h', String(h));

  url.searchParams.set('auto', 'format');

  if (quality) {
    url.searchParams.set('q', String(quality));
  }
  return url.href;
};
