import { ImageProps, ImgProps } from 'next/dist/shared/lib/get-img-props';
import { getImageProps } from 'next/dist/shared/lib/image-external';

import { ImageLoadFragment as EripiowearImageLoadFragment } from '@hultafors/eripiowear/types';
import { ImageLoadFragment as HellbergImageLoadFragment } from '@hultafors/hellberg/types';
import { ImageLoadFragment as HultaforsImageLoadFragment } from '@hultafors/hultafors/types';
import { ImageLoadFragment as HultaforsgroupImageLoadFragment } from '@hultafors/hultaforsgroup/types';
import { ImageLoadFragment as SnickersImageLoadFragment } from '@hultafors/snickers/types';
import { ImageLoadFragment as SolidgearImageLoadFragment } from '@hultafors/solidgear/types';

import { datoImageLoader } from '../dato-image-loader/dato-image-loader';

type ImageLoadFragment =
  | SnickersImageLoadFragment
  | SolidgearImageLoadFragment
  | HellbergImageLoadFragment
  | HultaforsImageLoadFragment
  | EripiowearImageLoadFragment
  | HultaforsgroupImageLoadFragment;

type DatoImageProps = {
  aspectRatio: number;
  aspectRatioLandscape?: number;
  image: ImageLoadFragment | undefined | null;
  maxSize: number;
  priority: boolean;
  sizes: string;
  sizesLandscape?: string;
};

export type DatoImage = {
  portrait: ImgProps | null;
  landscape: ImgProps | null;
};

export const getDatoImageProps = ({
  aspectRatio,
  aspectRatioLandscape,
  image,
  maxSize,
  priority,
  sizes,
  sizesLandscape,
}: DatoImageProps) => {
  const isDatoUrl = image?.url.includes('www.datocms-assets.com');
  if (!image?.url || !isDatoUrl) return null;

  const datoImage: DatoImage | null = {
    landscape: null,
    portrait: null,
  };

  const imageUrl = new URL(image.url);

  imageUrl.searchParams.set('q', '85');
  imageUrl.searchParams.set('crop', 'focalpoint');
  imageUrl.searchParams.set('fit', 'crop');
  imageUrl.searchParams.set('fp-x', String(image.focalPoint?.x || 0.5));
  imageUrl.searchParams.set('fp-y', String(image.focalPoint?.y || 0.5));
  imageUrl.searchParams.set('h', String(maxSize));
  imageUrl.searchParams.set('w', String(maxSize));

  const common: Omit<ImageProps, 'src'> = {
    alt: image.alt || '',
    loader: datoImageLoader,
    priority,
    quality: 85,
    sizes,
  };

  let width = maxSize * aspectRatio;
  let height = maxSize;

  const imageUrlPortrait = new URL(imageUrl.href);
  imageUrlPortrait.searchParams.set('h', String(height));
  imageUrlPortrait.searchParams.set('w', String(width));

  datoImage.portrait = getImageProps({
    ...common,
    height,
    src: imageUrlPortrait.href,
    width,
  }).props;

  if (aspectRatioLandscape) {
    width = maxSize;
    height = maxSize / aspectRatioLandscape;
    const imageUrlLandscape = new URL(imageUrl.href);
    imageUrlLandscape.searchParams.set('h', String(height));
    imageUrlLandscape.searchParams.set('w', String(width));

    datoImage.landscape = getImageProps({
      ...common,
      height: maxSize / aspectRatioLandscape,
      sizes: sizesLandscape,
      src: imageUrlLandscape.href,
      width: maxSize,
    }).props;
  }

  return datoImage;
};
