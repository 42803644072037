const cache5min = 'public, s-maxage=300';
const cache10min = 'public, s-maxage=600';
const cache15min = 'public, s-maxage=900';
export const cacheControl = {
  manifest: cache10min,
  page: cache15min,
  product: cache5min,
  robots: cache10min,
  search: cache10min,
  sitemap: cache10min,
};
